import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { useMemo } from 'react';

import { useTokenDetails } from '@app/hooks/token/useTokenDetails';
import { useTokenPriceV3 } from '@app/hooks/token/useTokenPriceV3';

export function useTokenPriceValue(
  value: string,
  tokenAddress: string | undefined
) {
  const {
    data: ethPrice,
    isLoading: loadingPrice,
    fetchStatus: fetchStatusPrice
  } = useTokenPriceV3({
    id: 'weth'
  });

  const {
    data: tokenDetails,
    isLoading: loadingDetails,
    fetchStatus: fetchStatusDetails
  } = useTokenDetails({
    id: tokenAddress
  });

  const isLoadingData =
    loadingPrice &&
    fetchStatusPrice !== 'idle' &&
    loadingDetails &&
    fetchStatusDetails !== 'idle';

  const priceValue = useMemo(() => {
    if (!ethPrice) {
      return 0;
    }

    if (!tokenDetails && tokenAddress === NATIVE_TOKEN_ADDRESS) {
      return ethPrice && value ? Number(value) * Number(ethPrice) : 0;
    }

    if (!tokenDetails) {
      return 0;
    }

    const { derivedETH } = tokenDetails;

    const price = +derivedETH * +ethPrice;

    return price && value ? Number(value) * price : 0;
  }, [ethPrice, tokenAddress, tokenDetails, value]);

  return {
    value: priceValue,
    isLoading: isLoadingData
  };
}
