import localforage from 'localforage';
import { useCallback } from 'react';
import { useAsync } from 'react-use';

import { DEFAULT_SETTINGS, SETTINGS_STORAGE_KEY } from '@app/constants/swap';

export type SwapSetting = {
  deadline: number;
  slippage: number;
};

export async function getSwapSettings() {
  const settings = await localforage.getItem(SETTINGS_STORAGE_KEY);

  return (settings ?? DEFAULT_SETTINGS) as SwapSetting;
}

export function useSwapSettings(): {
  settings: SwapSetting | undefined;
  updateSettings: (values: SwapSetting) => Promise<SwapSetting>;
} {
  const { value } = useAsync(async () => {
    return getSwapSettings();
  });

  const updateSettings = useCallback(async (values: SwapSetting) => {
    return localforage.setItem(SETTINGS_STORAGE_KEY, values);
  }, []);

  return {
    settings: value,
    updateSettings
  };
}
